<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <!-- <h1>Hola, mi nombre es</h1> -->
                    <h1>{{"Banner.Pretitle" | translate}}</h1>
                </div>
                <div class='banner-title'>
                    <h2>{{name}}</h2>
                    <h4>{{profession}}</h4>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]="(description | slice:0:400) + (description.length > 400 ? '...' : '')" class="mt-4"
                        title="{{ description }}"></p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "banner", "email")' [href]="mailTo"
                    target="_black" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
        </div>
    </div>
</section>