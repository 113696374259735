import { Component, OnInit, AfterViewInit, Input, OnChanges } from '@angular/core';

import { trigger, state, style, animate, transition, stagger, query } from "@angular/animations"
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('bannerTrigger', [
      transition(":enter", [
        query("*", [
          style({ opacity: 0, transform: "translateX(-50px)" }),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ])
      ])
    ])
  ]
})
export class BannerComponent implements OnInit, OnChanges {


  @Input()
  name;

  @Input()
  profession;

  @Input()
  description;

  @Input()
  email;

  @Input()
  homeDetails;

  mailTo;

  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    if (this.email) {
      this.mailTo = 'mailto:' + this.email;
      console.log(this.mailTo);
    }
  }

  ngOnChanges(): void {
    if (this.email) {
      this.mailTo = 'mailto:' + this.email;
      console.log(this.mailTo);
    }
  }


}
