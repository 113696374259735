<div class="biodata-container">
    <h1>{{name}}</h1>
    <div class="image-slider-container">
        <a class="prev" (click)="moveToPreviousImage()">&#10094;</a>
        <div class="photo-container">
            <img (click)="opennImage(uploadedImages[activeImageIndex].path)" [src]="uploadedImages[activeImageIndex].path" alt="Profile Photo">
        </div>
        <a class="next" (click)="moveToNextImage()">&#10095;</a>
    </div>



    <div class="details-container mt-4">
        <p><strong style="color: beige;margin-top: 2rem;">Name:</strong> {{name}}</p>
        <p><strong style="color: beige;">Contact Number:</strong> {{phone}}</p>
        <p><strong style="color: beige;">Email:</strong> {{email}}</p>
        <p><strong style="color: beige;">Address:</strong> {{address}}</p>
        <p><strong style="color: beige;">Date of Birth:</strong> {{dob}}</p>
        <p><strong style="color: beige;">Gender:</strong> {{gender}}</p>
        <p><strong style="color: beige;">Marital Status:</strong> {{maritalStatus}}</p>
        <p><strong style="color: beige;">Religion:</strong> {{religion}}</p>
        <p><strong style="color: beige;">Caste:</strong> {{caste}}</p>
        <p><strong style="color: beige;">Height:</strong> {{height}}</p>
        <p><strong style="color: beige;">Complexion:</strong> {{complexion}}</p>
        <p><strong style="color: beige;">Blood Group:</strong> {{bloodGroup}}</p>
        <p><strong style="color: beige;">Education:</strong> {{education}}</p>
        <p><strong style="color: beige;">Other Qualifications:</strong> {{otherQualifications}}</p>
        <p><strong style="color: beige;">Hobbies:</strong> {{hobbies}}</p>
        <p><strong style="color: beige;">Career:</strong> {{career}}</p>
        <p><strong style="color: beige;">Mother's Name:</strong> {{motherName}}</p>
        <p><strong style="color: beige;">Father's Name:</strong> {{fatherName}}</p>
        <p><strong style="color: beige;">Father's Job:</strong> {{fatherJob}}</p>
        <p><strong style="color: beige;">Mother's Name:</strong> {{motherJob}}</p>
        <p><strong style="color: beige;">Siblings Details:</strong> {{siblingsDetails}}</p>

    </div>
</div>