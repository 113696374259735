<div class="relative">
    <img src="https://images.pexels.com/photos/3747463/pexels-photo-3747463.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
        class="absolute inset-0 object-cover w-full h-full" alt="" />
    <div class="relative bg-gray-900 bg-opacity-75">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div class="flex flex-col items-center justify-between xl:flex-row">
                <div class="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
                    <h2
                        class="max-w-lg mb-6 font-sans text-4xl lg:text-5xl xl:text-6xl font-bold tracking-tight text-white sm:text-5xl sm:leading-none md:text-6xl md:leading-none">
                        <span class="text-teal-accent-400">{{homeDetails?.name}}</span>
                    </h2>

                    <p class="max-w-xl mb-4 text-base text-gray-400 md:text-lg">
                        {{homeDetails?.description}}
                    </p>
                    <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-2">
                        <!-- Skill 1 -->
                        <div class="p-2" *ngIf="homeDetails.skill1">
                            <p class="mb-2">
                                <a
                                    class="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700">
                                    {{homeDetails.skill1}}
                                    <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
                                        <path
                                            d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z">
                                        </path>
                                    </svg>
                                </a>
                            </p>
                        </div>
                        <!-- Skill 2 -->
                        <div class="p-2" *ngIf="homeDetails.skill2">
                            <p class="mb-2">
                                <a
                                    class="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700">
                                    {{homeDetails.skill2}}
                                    <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
                                        <path
                                            d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z">
                                        </path>
                                    </svg>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-2">
                        <!-- Skill 3 (assuming there might be a typo in your structure and you intended to show skill3 and skill4) -->
                        <div class="p-2" *ngIf="homeDetails.skill3">
                            <p class="mb-2">
                                <a
                                    class="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700">
                                    {{homeDetails.skill3}}
                                    <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
                                        <path
                                            d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z">
                                        </path>
                                    </svg>
                                </a>
                            </p>
                        </div>
                        <!-- Skill 4 -->
                        <div class="p-2" *ngIf="homeDetails.skill4">
                            <p class="mb-2">
                                <a
                                    class="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700">
                                    {{homeDetails.skill4}}
                                    <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
                                        <path
                                            d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z">
                                        </path>
                                    </svg>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-2">
                        <!-- Skill 5 -->
                        <div class="p-2" *ngIf="homeDetails.skill5">
                            <p class="mb-2">
                                <a
                                    class="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700">
                                    {{homeDetails.skill5}}
                                    <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
                                        <path
                                            d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z">
                                        </path>
                                    </svg>
                                </a>
                            </p>
                        </div>
                        <!-- Skill 6 -->
                        <div class="p-2" *ngIf="homeDetails.skill6">
                            <p class="mb-2">
                                <a
                                    class="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700">
                                    {{homeDetails.skill6}}
                                    <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
                                        <path
                                            d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z">
                                        </path>
                                    </svg>
                                </a>
                            </p>
                        </div>
                    </div>



                </div>
                <div class="w-full max-w-xl xl:px-8 xl:w-5/12">
                    <div class="bg-white rounded shadow-2xl p-7 sm:p-10">
                        <h3 class="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
                            Send Mail up To Contact
                        </h3>
                        <form>
                            <div class="mb-1 sm:mb-2">
                                <label for="firstName" class="inline-block mb-1 font-medium">First name</label>
                                <input placeholder="John" required="" type="text"
                                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                    id="firstName" name="firstName" />
                            </div>
                            <div class="mb-1 sm:mb-2">
                                <label for="lastName" class="inline-block mb-1 font-medium">Last name</label>
                                <input placeholder="Doe" required="" type="text"
                                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                    id="lastName" name="lastName" />
                            </div>
                            <div class="mb-1 sm:mb-2">
                                <label for="email" class="inline-block mb-1 font-medium">E-mail</label>
                                <input required="" type="text" disabled [(ngModel)]="email"
                                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                    id="email" name="email" />
                            </div>
                            <div class="mt-4 mb-2 sm:mb-4">
                                <button type="submit" (click)="sendMail()"
                                    class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                                    Send Mail
                                </button>
                            </div>
                            <p class="text-xs text-gray-600 sm:text-sm">
                                Send email to contact me.
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>















<!-- https://gist.github.com/goodreds/3d044027175954984fb96c7407a955ab -->

<!-- Container -->
<div *ngFor="let project of projects"
    class="relative flex flex-col items-center mx-auto lg:flex-row-reverse lg:max-w-5xl lg:mt-12 xl:max-w-6xl">

    <!-- Image Column -->
    <div class="w-full h-64 lg:w-1/2 lg:h-auto">
        <img class="h-full w-full object-cover" src="https://picsum.photos/id/1018/2000" alt="Winding mountain road">
    </div>
    <!-- Close Image Column -->

    <!-- Text Column -->
    <div data-aos="fade-up" data-aos-duration="1000"
        class="max-w-lg bg-white md:max-w-2xl md:z-10 md:shadow-lg md:absolute md:top-0 md:mt-48 lg:w-3/5 lg:left-0 lg:mt-20 lg:ml-20 xl:mt-24 xl:ml-12">
        <!-- Text Wrapper -->
        <div class="flex flex-col p-12 md:px-16">
            <h2 class="text-2xl font-medium uppercase text-green-800 lg:text-4xl" title="{{project.name}}">
                {{project.name |
                slice:0:20}}{{project.name.length > 20 ? '...' : ''}}
            </h2>
            <p class="mt-4" title="{{project.description}}">
                {{project.description | slice:0:200}}{{project.description.length > 200 ? '...' : ''}}

            </p>
            <!-- Button Container -->
            <div class="mt-8">
                <a (click)="openLink(project.codeLink)"
                    class="inline-block w-full text-center text-lg font-medium text-gray-100 bg-green-600 border-solid border-2 border-gray-600 py-4 px-10 hover:bg-green-800 hover:shadow-md md:w-48 ml-2 cursor-pointer">View
                    Code
                </a>

                <a (click)="openLink(project.link)"
                    class="inline-block w-full text-center text-lg font-medium text-gray-100 bg-green-600 border-solid border-2 border-gray-600 py-4 px-10 hover:bg-green-800 hover:shadow-md md:w-48 ml-2 cursor-pointer">view
                    App
                </a>
            </div>

        </div>
        <!-- Close Text Wrapper -->
    </div>
    <!-- Close Text Column -->

</div>


<footer class="flex flex-col space-y-10 justify-center m-10">



    <div class="flex justify-center space-x-5">
        <span *ngFor="let media of socialMediaDetails">
            <a style="font-size: 1.5rem;" href="{{media.link}}" target="_blank"><i
                    class="fab fa-{{media.name | lowercase}}"></i></a>
        </span>

    </div>
    <p class="text-center text-gray-700 font-medium">&copy; 2024 Company Ltd. All rights reservered.</p>
</footer>