<div class="flex flex-col items-center justify-center h-screen love-theme-gradient">
    <div class="flex flex-col items-center justify-center p-8 rounded-lg">
        <!-- Responsive text size using TailwindCSS -->
        <p class="text-sm sm:text-md md:text-lg font-semibold mb-4" *ngIf="selectedString"> {{ selectedString }}</p>
        <button [ngClass]="{'animate-dice': isAnimating}"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-10 rounded"
            (click)="getRandomString()">
            {{ isAnimating ? 'Rolling...' : 'Click to select a random task' }}
        </button>
    </div>
</div>