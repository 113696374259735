import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { SubdomainService } from '../subdomain-service.service';
import { UserProfileServiceService } from '../user-profile-service.service';

@Component({
  selector: 'app-template5-service',
  templateUrl: './template5-service.component.html',
  styleUrls: ['./template5-service.component.css']
})
export class Template5ServiceComponent implements OnInit, AfterViewInit {


  @Input()
  email;

  @Input()
  homeDetails;

  @Input()
  projects;

  @Input()
  socialMediaDetails;

  @Input()
  views;

  @Input()
  documents;

  services = [];
  works = [];

  constructor(private userApi: UserProfileServiceService,
    private subDomainService: SubdomainService) {

  }

  ngOnInit(): void {
    this.userApi.fetchServices(this.subDomainService.getSubdomain()).subscribe
      ((data) => {
        this.services = data;
      });

    this.userApi.fetchWork(this.subDomainService.getSubdomain()).subscribe
      ((data) => {
        this.works = data;
      });
  }

  open = true;

  toggleOpen() {
    this.open = !this.open;
  }

  servicesImage = '';
  productsImage = '';

  ngAfterViewInit(): void {

    const randomNumber = Math.floor(Math.random() * 6) + 1;

    this.servicesImage = `/assets/img/services_${randomNumber}.png`;

    this.productsImage = `/assets/img/product_${randomNumber}.png`;


    document.addEventListener('DOMContentLoaded', function () {
      // Add event listener to each navigation link
      document.querySelectorAll('.navigation-link').forEach(function (link) {
        link.addEventListener('click', function () {
          var targetId = link.id + 'Details'; // Construct the target section ID
          var targetSection = document.getElementById(targetId);
          if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
          }
        });
      });
    });

    let link = document.getElementById('seeFeatures');
    link.addEventListener('click', function () {

      var targetSection = document.getElementById('template5ServicesDetails');
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    });


    link = document.getElementById('template5About');
    link.addEventListener('click', function () {

      var targetSection = document.getElementById('template5AboutDetails');
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    });

    link = document.getElementById('template5Services');
    link.addEventListener('click', function () {

      var targetSection = document.getElementById('template5ServicesDetails');
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    });

    link = document.getElementById('template5Contact');
    link.addEventListener('click', function () {

      var targetSection = document.getElementById('template5ContactDetails');
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    });


    link = document.getElementById('template5Work');
    link.addEventListener('click', function () {

      var targetSection = document.getElementById('template5WorkDetails');
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    });


  }

  toggleView(index: number) {
    this.services[index].viewFull = !this.services[index].viewFull;
  }

  toggleWorkView(index: number) {
    this.works[index].viewFull = !this.works[index].viewFull;
  }

  descriptionToShow(service) {
    return service.viewFull || service.description.length <= 200 ? service.description : `${service.description.substring(0, 200)}...`;
  }


  getPath(path) {
    if (path === 'services') {
      const randomNumber = Math.floor(Math.random() * 6) + 1;
      return `/assets/img/services_${randomNumber}.png`;
    }

    if (path === 'products') {
      const randomNumber = Math.floor(Math.random() * 6) + 1;
      return `/assets/img/products_${randomNumber}.png`;
    }

  }

  sendMail() {
    window.location.href = `mailto:${this.email}`;
  }

  openImage(url: string): void {
    window.open(url, '_blank');
  }

}
