import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from "src/app/services/language/language.service"
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UserProfileServiceService } from './user-profile-service.service';
import { SubdomainService } from './subdomain-service.service';
import { HomeComponent } from './components/home/home.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = '';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translateService: TranslateService,
    private location: Location,
    private router: Router,
    private languageService: LanguageService,
    private userApiService: UserProfileServiceService,
    private subDomainService: SubdomainService,
  ) {
  }

  socialMediaDetails = [];
  subdomain = '';

  template_number = 0;


  ngOnInit(): void {

    this.subdomain = this.subDomainService.getSubdomain();
    if (this.subdomain) {
      this.title = this.subdomain;
      this.userApiService.fetchEmail(this.subdomain).subscribe((details) => {
        localStorage.setItem('email', details[0]);
        document.getElementById('myProfileName').setAttribute('innerHtml', details[0]);

        // document.getElementById('page-top').setAttribute('id', 'temp_2');

        this.titleService.setTitle(this.subdomain);
        this.template_number = details[1];

        this.fetchDetails();

        this.fetchAndIncrementViewCount(this.subdomain);
      });

      this.userApiService.fetchSocialMedia(this.subdomain).subscribe((details) => {
        this.socialMediaDetails = details;
      });
    }

    this.languageService.initLanguage();

    AOS.init();

  }

  homeDetails = {};
  email = '';
  projects = [];
  experiences = [];

  documents = [];

  fetchDetails() {

    this.userApiService.fetchEmail(this.subdomain);
    this.userApiService.fetchHomeDetails(this.subdomain).subscribe((details) => {
      this.homeDetails = details;


      this.metaService.addTags([
        { name: 'keywords', content: details.name },
        { name: 'name', content: details.tagline },
        { name: 'description', content: details.description },
      ]);

    });

    console.log(this.homeDetails);
    this.email = localStorage.getItem('email');

    this.userApiService.fetchWork(this.subdomain).subscribe((details) => {
      this.projects = details;
    });

    this.userApiService.fetchExperience(this.subdomain).subscribe((details) => {
      this.experiences = details;
    });


    this.userApiService.fetchDocDetails(this.subdomain).subscribe((details) => {
      this.documents = details;
    });



  }

  views = 0;
  fetchAndIncrementViewCount(viewName) {

    this.userApiService.incrementViewCount(viewName).subscribe({
      next: (response) => console.log('View count incremented', response),
      error: (error) => console.error('Error incrementing view count', error),
    });

    this.userApiService.getViewCount(viewName).subscribe({
      next: (count: number) => { this.views = count },
      error: (error) => console.error('Error fetching view count', error),
    });

  }

}
