<!-- ======= Portfolio Section ======= -->
<section id="template_2_work" class="portfolio-mf sect-pt4 pb-5 route">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="title-box text-center pt-5">
                    <h3 class="title-a">
                        Portfolio
                    </h3>
                    <p class="subtitle-a">
                        Some projects that I have done currently
                    </p>
                    <div class="line-mf"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let project of projectsService.projects" class="col-md-4">
                <div class="work-box">
                    <a href="{{project.web}}" data-gall="portfolioGallery" target="_blank">
                        <div class="work-img">
                            <img [src]="project.url" alt="Project Portfolio" class="img-fluid">
                        </div>
                    </a>
                    <div class="work-content">
                        <div class="row">
                            <div class="col-sm-8">
                                <h2 class="w-title">{{ project.title }}</h2>
                                <div class="w-more">
                                    <span class="w-ctegory">{{ project.category }}</span> / <span class="w-date">{{ project.date }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="w-like pl-3">
                                    <a href="{{project.web}}" target="_blank"> <span class="ion-android-globe"></span></a>
                                </div>
                                <div class="w-like">
                                    <a href="{{project.github}}" target="_blank"> <span class="ion-social-github"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section><!-- End Portfolio Section -->