import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UserProfileServiceService } from '../user-profile-service.service';
import { SubdomainService } from '../subdomain-service.service';
import Glide from '@glidejs/glide';
import * as AOS from 'aos';

@Component({
  selector: 'app-template4',
  templateUrl: './template4.component.html',
  styleUrls: ['./template4.component.css']
})
export class Template4Component implements OnInit, OnDestroy {


  value = 1000;

  @Input()
  email;

  @Input()
  homeDetails;

  @Input()
  projects;

  @Input()
  experiences;

  @Input()
  socialMediaDetails;

  @Input()
  views;

  @Input()
  documents;


  motherName = 'motherName';
  fatherName = 'fatherName';
  name = 'name';
  contactNumber = '738296387263';

  // name: string = '';
  dob: string = '';
  gender: string = '';
  maritalStatus: string = '';
  religion: string = '';
  caste: string = '';
  height: string = '';
  complexion: string = '';
  bloodGroup: string = '';
  education: string = '';
  otherQualifications: string = '';
  hobbies: string = '';
  career: string = '';




  // fatherName: string = ''; // Initialize to empty string
  fatherJob: string = ''; // Initialize to empty string
  // motherName: string = ''; // Initialize to empty string
  motherJob: string = ''; // Initialize to empty string
  siblingsDetails: string = `These enhancements contribute to a more visually appealing and reader-friendly biodata page, with a clear emphasis on the individual's name, ensuring it immediately captures the viewer's attention. Feel free to further adjust the colors, font sizes, or spacing to match specific preferences or branding requirements.`; // Initialize to empty string


  phone: string = '';
  // email: string = '';
  address: string = '';

  uploadedImages = [];

  @ViewChild('glide') glideElement: ElementRef;

  constructor(private userApi: UserProfileServiceService,
    private subDomainService: SubdomainService) { }


  ngOnChanges(changes: SimpleChanges): void {

    AOS.init();

    if (changes.uploadedImages && !changes.uploadedImages.firstChange) {
      this.reinitializeGlide();
    }
  }


  private glideInstance: Glide;

  private initializeGlide() {
    this.glideInstance = new Glide(this.glideElement.nativeElement, {
      type: 'carousel',
      startAt: 0,
      perView: 2
    });

    this.glideInstance.mount();
  }

  private reinitializeGlide() {
    if (this.glideInstance) {
      this.glideInstance.destroy();
    }
    this.initializeGlide();
  }


  ngOnInit(): void {

    document.body.style.background = 'linear-gradient(to right, #2E3192, #1BFFFF)';

    // this.email = 'fsgdfjh@hdkjgkhdjf.com';

    var detailsSections = document.querySelectorAll('#contactDetails');
    detailsSections.forEach(function (section: any) {
      section.style.display = 'none';
    });

    var detailsSections1 = document.querySelectorAll('#personalDetails');
    detailsSections1.forEach(function (section: any) {
      section.style.display = 'none';
    });


    // detailsSections = document.querySelectorAll('.textGroups1');
    // detailsSections.forEach(function (section: any) {
    //   section.style.display = 'none';
    // });


    this.userApi.fetchDocDetails(this.subDomainService.getSubdomain()).subscribe((data) => {
      this.uploadedImages = data;
    });


    this.userApi.fetchPersonalInfo(this.subDomainService.getSubdomain()).subscribe((data) => {
      // Assuming data is an array with one object
      if (data.length > 0) {
        const fetchedData = data[0];
        this.name = fetchedData.name || '';
        this.dob = fetchedData.dob || '';
        this.gender = fetchedData.gender || '';
        this.maritalStatus = fetchedData.maritalStatus || '';
        this.religion = fetchedData.religion || '';
        this.caste = fetchedData.caste || '';
        this.height = fetchedData.height || '';
        this.complexion = fetchedData.complexion || '';
        this.bloodGroup = fetchedData.bloodGroup || '';
        this.education = fetchedData.education || '';
        this.otherQualifications = fetchedData.otherQualifications || '';
        this.hobbies = fetchedData.hobbies || '';
        this.career = fetchedData.career || '';
      }
    });

    this.userApi.fetchFamilyDetails(this.subDomainService.getSubdomain()).subscribe((data) => {
      // Assuming data is an array with one object
      if (data.length > 0) {
        const fetchedData = data[0];
        this.fatherName = fetchedData.fatherName || '';
        this.fatherJob = fetchedData.fatherJob || '';
        this.motherName = fetchedData.motherName || '';
        this.motherJob = fetchedData.motherJob || '';
        // this.siblingsDetails = fetchedData.siblingsDetails || '';
      }
    });


    this.userApi.fetchContactInfo(this.subDomainService.getSubdomain()).subscribe((data) => {
      if (data.length > 0) {
        const fetchedData = data[0];
        this.phone = fetchedData.phone || '';
        this.email = fetchedData.email || '';
        this.address = fetchedData.address || '';
      }
    });


  }

  showDetails(id) {
    // Hide all details sections

    var detailsSections = document.querySelectorAll('#contactDetails');
    detailsSections.forEach(function (section: any) {
      section.style.display = 'none';
    });

    var detailsSections1 = document.querySelectorAll('#personalDetails');
    detailsSections1.forEach(function (section: any) {
      section.style.display = 'none';
    });

    // Show the selected details section
    var selectedSection = document.getElementById(id);
    if (selectedSection) {
      selectedSection.style.display = 'block';
    }
  }



  ngAfterViewInit(): void {
    new Glide(this.glideElement.nativeElement, {
      type: 'carousel',
      startAt: 0,
      perView: 2
    }).mount();
  }

  ngOnDestroy(): void {
    document.body.style.background = '';
  }

  activeImageIndex = 0;

  moveToNextImage(): void {
    if (this.activeImageIndex < this.uploadedImages.length - 1) {
      this.activeImageIndex++;
    } else {
      this.activeImageIndex = 0; // Loop back to first image
    }
  }

  moveToPreviousImage(): void {
    if (this.activeImageIndex > 0) {
      this.activeImageIndex--;
    } else {
      this.activeImageIndex = this.uploadedImages.length - 1; // Loop back to last image
    }
  }

  opennImage(imageLink) {
    window.open(imageLink, '_blank');
  }
}
