import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit, OnChanges {

  active = 0

  @Input()
  experience;

  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    console.log(this.experience);
  }

  ngOnChanges(): void {
    console.log(this.experience);
  }

}
