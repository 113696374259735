import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-template7-single-page',
  templateUrl: './template7-single-page.component.html',
  styleUrls: ['./template7-single-page.component.css']
})
export class Template7SinglePageComponent implements OnInit {

  constructor() { }

  @Input()
  email;

  @Input()
  homeDetails;

  @Input()
  projects;

  @Input()
  socialMediaDetails;

  @Input()
  views;

  @Input()
  documents;


  ngOnInit(): void {
  }

  sendMail() {
    window.location.href = `mailto:${this.email}`;
  }


  openLink(link) {
    window.open(link, '_blank');
  }
}
