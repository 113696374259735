<section class="section archive" [@archiveAnimation] *ngIf='state'>
    <div class="container">
        <div class="archive-title">
            <h1 class="big-title">Proyectos</h1>
            <p class="archive-subtitle big-subtitle">Una lista mas completa</p>
        </div>
        <div class="archive-table-container">
            <table class="archive__StyledTable-lXDeB haZMKe">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Title</th>
                        <th class="hide-on-mobile">Made at</th>
                        <th class="hide-on-mobile">Built with</th>
                        <th>Link</th>
                    </tr>
                </thead>
                <tbody>
                    <tr data-sr-id="3">
                        <td class="proyect-date">2020</td>
                        <td class="proyect-title">Sistem web Avicola</td>
                        <td class="proyect-company"><span>Monagas - VE</span></td>
                        <td class="proyect-skills">
                            <span>Laravel<span class="separator">·</span></span>
                            <span>Webpack</span>
                        </td>
                        <td class="proyect-links">
                            <span><a style='color: inherit' href="https://github.com/andresjosehr/Avicola"><i class="ml-4 fab fa-github"></i></a></span>
                            <span><a style='color: inherit' href="http://avicola.luzzidigital.fav/"><i class="ml-4 fas fa-external-link-alt"></i></a></span>
                        </td>
                    </tr>
                    <tr data-sr-id="3">
                        <td class="proyect-date">2020</td>
                        <td class="proyect-title">Cenem</td>
                        <td class="proyect-company"><span>Monagas - VE</span></td>
                        <td class="proyect-skills">
                            <span>Wordpress<span class="separator">·</span></span>
                            <span>Divi</span>
                        </td>
                        <td class="proyect-links">
                            <span><a style='color: inherit' href="http://cenem.luzzidigital.fav/"><i class="ml-4 fas fa-external-link-alt"></i></a></span>
                        </td>
                    </tr>
                    <tr data-sr-id="3">
                        <td class="proyect-date">2020</td>
                        <td class="proyect-title">ClerckContable</td>
                        <td class="proyect-company"><span>Caracas - VE</span></td>
                        <td class="proyect-skills">
                            <span>HTML<span class="separator">·</span></span>
                            <span>CSS</span>
                        </td>
                        <td class="proyect-links">
                            
                            <span><a style='color: inherit' href="https://github.com/andresjosehr/Clerkcontable"><i class="ml-4 fab fa-github"></i></a></span>
                            <span><a style='color: inherit' href="https://clerckcontable.luzzidigital.fav"><i class="ml-4 fas fa-external-link-alt"></i></a></span>
                        </td>
                    </tr>
                    <tr data-sr-id="3">
                        <td class="proyect-date">2020</td>
                        <td class="proyect-title">Innerfire</td>
                        <td class="proyect-company"><span>Monagas - VE</span></td>
                        <td class="proyect-skills">
                            <span>Wordpress<span class="separator">·</span></span>
                            <span>HTML<span class="separator">·</span></span>
                            <span>CSS</span>
                        </td>
                        <td class="proyect-links">
                            <span><a style='color: inherit' href="http://innerfire.luzzidigital.fav/"><i class="ml-4 fas fa-external-link-alt"></i></a></span>
                        </td>
                    </tr>
                    <tr data-sr-id="3">
                        <td class="proyect-date">2020</td>
                        <td class="proyect-title">Inver</td>
                        <td class="proyect-company"><span>Monagas - VE</span></td>
                        <td class="proyect-skills">
                            <span>Wordpress<span class="separator">·</span></span>
                            <span>HTML<span class="separator">·</span></span>
                            <span>CSS</span>
                        </td>
                        <td class="proyect-links">
                            <span><a style='color: inherit' href="https://github.com/andresjosehr/inver-content/"><i class="ml-4 fab fa-github"></i></a></span>
                            <span><a style='color: inherit' href="http://inver.luzzidigital.fav/"><i class="ml-4 fas fa-external-link-alt"></i></a></span>
                        </td>
                    </tr>
                    <tr data-sr-id="3">
                        <td class="proyect-date">2020</td>
                        <td class="proyect-title">Persimas</td>
                        <td class="proyect-company"><span>Caracas - VE</span></td>
                        <td class="proyect-skills">
                            <span>Bootstrap<span class="separator">·</span></span>
                            <span>HTML<span class="separator">·</span></span>
                            <span>CSS</span>
                        </td>
                        <td class="proyect-links">
                            <span><a style='color: inherit' href="https://github.com/andresjosehr/Persimas"><i class="ml-4 fab fa-github"></i></a></span>
                            <span><a style='color: inherit' href="https://persimas.luzzidigital.fav"><i class="ml-4 fas fa-external-link-alt"></i></a></span>
                        </td>
                    </tr>
                    <tr data-sr-id="3">
                        <td class="proyect-date">2020</td>
                        <td class="proyect-title">Rodrigo</td>
                        <td class="proyect-company"><span>Caracas - VE</span></td>
                        <td class="proyect-skills">
                            <span>Bootstrap<span class="separator">·</span></span>
                            <span>HTML<span class="separator">·</span></span>
                            <span>CSS</span>
                        </td>
                        <td class="proyect-links">
                            <span><a style='color: inherit' href="https://github.com/andresjosehr/Rodrigo"><i class="ml-4 fab fa-github"></i></a></span>
                            <span><a style='color: inherit' href="https://rodrigo.luzzidigital.fav"><i class="ml-4 fas fa-external-link-alt"></i></a></span>
                        </td>
                    </tr>
                    <tr data-sr-id="3">
                        <td class="proyect-date">2020</td>
                        <td class="proyect-title">Salud al Dia</td>
                        <td class="proyect-company"><span>Caracas - VE</span></td>
                        <td class="proyect-skills">
                            <span>Wordpress<span class="separator">·</span></span>
                            <span>Divi</span>
                        </td>
                        <td class="proyect-links">
                            <span><a style='color: inherit' href="https://saludaldia.luzzidigital.fav"><i class="ml-4 fas fa-external-link-alt"></i></a></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>