import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubdomainService {
  getSubdomain(): string {
    const url = window.location.hostname;
    const subdomain = url.split('.')[0];
    return subdomain;
    // return 'test2121';
    // return 'cena-biodata';
  }
}
