<!-- ======= Header/ Navbar ======= -->
<nav class="navbar navbar-b navbar-trans navbar-expand-md fixed-top" id="mainNav">
    <div class="container">
        <a class="navbar-brand js-scroll" href="#page-top">{{ name }}
        </a>
        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault"
            aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span></span>
            <span></span>
            <span></span>
        </button>
        <div class="navbar-collapse collapse justify-content-end" id="navbarDefault">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link js-scroll active" id="homeLink">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link js-scroll" id="aboutLink">About me</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link js-scroll" id="serviceLink">Services</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link js-scroll" id="workLink">Portfolio</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link js-scroll" id="footerLink">Contact me</a>
                </li>

                <li>
                    <a (click)="toggleViewCount()" class="text-white cursor-pointer">
                        <!-- Show eye icon when views are NOT shown, i.e., showViews is false -->
                        <i *ngIf="!showViews" class="fas fa-eye"></i>
                        <!-- Show eye-slash icon and views when views are shown, i.e., showViews is true -->
                        <span *ngIf="showViews">
                            <i class="fas fa-eye-slash"></i>: {{ views }}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>