import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { InfoPageService } from '../../services/info-page.service';
declare var jQuery: any;

@Component({
  selector: 'app-header-2',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent2 implements OnInit, AfterViewInit {

  constructor(public _service: InfoPageService) { }

  @Input()
  name;



  @Input()
  tagline;




  @Input()
  description;



  @Input()
  email;

  @Input()
  views = 0;

  ngOnInit(): void {





    (function ($) {
      "use strict";

      var nav = $('nav');
      var navHeight = nav.outerHeight();

      $('.navbar-toggler').on('click', function () {
        if (!$('#mainNav').hasClass('navbar-reduce')) {
          $('#mainNav').addClass('navbar-reduce');
        }
      })

      // Closes responsive menu when a scroll trigger link is clicked
      $('.js-scroll').on("click", function () {
        $('.navbar-collapse').collapse('hide');
      });

      // Activate scrollspy to add active class to navbar items on scroll
      $('body').scrollspy({
        target: '#mainNav',
        offset: navHeight
      });

      /*--/ Navbar Menu Reduce /--*/
      $(window).trigger('scroll');
      $(window).on('scroll', function () {
        var pixels = 50;
        var top = 1200;
        if ($(window).scrollTop() > pixels) {
          $('.navbar-expand-md').addClass('navbar-reduce');
          $('.navbar-expand-md').removeClass('navbar-trans');
        } else {
          if (!$('#navbarDefault').hasClass('show')) {
            $('.navbar-expand-md').removeClass('navbar-reduce');
          }
          $('.navbar-expand-md').addClass('navbar-trans');
        }
        if ($(window).scrollTop() > top) {
          $('.scrolltop-mf').fadeIn(1000, "easeInOutExpo");
        } else {
          $('.scrolltop-mf').fadeOut(1000, "easeInOutExpo");
        }
      });

    })(jQuery);
  }

  ngAfterViewInit(): void {

    var homeLink = document.getElementById('homeLink');

    // Add click event listener to the "About me" link
    homeLink.addEventListener("click", function (e) {
      e.preventDefault(); // Prevent the default anchor link behavior

      // Select the target element to scroll to
      var aboutSection = document.getElementById('template_2_home');
      console.log(aboutSection);

      // Use scrollIntoView to scroll to the about section
      aboutSection.scrollIntoView({
        behavior: 'smooth', // Smooth scroll
        block: 'start' // Align to the start of the viewport
      });
    });


    // Select the "About me" link by its ID
    var aboutLink = document.getElementById('aboutLink');

    // Add click event listener to the "About me" link
    aboutLink.addEventListener("click", function (e) {
      console.log(aboutLink);
      e.preventDefault(); // Prevent the default anchor link behavior

      // Select the target element to scroll to
      var aboutSection = document.getElementById('template_2_about');
      console.log(aboutSection);

      // Use scrollIntoView to scroll to the about section
      aboutSection.scrollIntoView({
        behavior: 'smooth', // Smooth scroll
        block: 'start' // Align to the start of the viewport
      });
    });



    var serviceLink = document.getElementById('serviceLink');

    // Add click event listener to the "About me" link
    serviceLink.addEventListener("click", function (e) {
      console.log(aboutLink);
      e.preventDefault(); // Prevent the default anchor link behavior

      // Select the target element to scroll to
      var aboutSection = document.getElementById('template_2_services');
      console.log(aboutSection);

      // Use scrollIntoView to scroll to the about section
      aboutSection.scrollIntoView({
        behavior: 'smooth', // Smooth scroll
        block: 'start' // Align to the start of the viewport
      });
    });



    var workLink = document.getElementById('workLink');

    // Add click event listener to the "About me" link
    workLink.addEventListener("click", function (e) {
      console.log(aboutLink);
      e.preventDefault(); // Prevent the default anchor link behavior

      // Select the target element to scroll to
      var aboutSection = document.getElementById('template_2_work');
      console.log(aboutSection);

      // Use scrollIntoView to scroll to the about section
      aboutSection.scrollIntoView({
        behavior: 'smooth', // Smooth scroll
        block: 'start' // Align to the start of the viewport
      });
    });

    var footerLink = document.getElementById('footerLink');

    // Add click event listener to the "About me" link
    footerLink.addEventListener("click", function (e) {
      console.log(aboutLink);
      e.preventDefault(); // Prevent the default anchor link behavior

      // Select the target element to scroll to
      var aboutSection = document.getElementById('template_2_footer');
      console.log(aboutSection);

      // Use scrollIntoView to scroll to the about section
      aboutSection.scrollIntoView({
        behavior: 'smooth', // Smooth scroll
        block: 'start' // Align to the start of the viewport
      });
    });


  }

  showViews = false;
  toggleViewCount() {
    this.showViews = !this.showViews;
  }

}
