import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { SubdomainService } from 'src/app/subdomain-service.service';
import { UserProfileServiceService } from 'src/app/user-profile-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  homeDetails = null;
  email = null;
  projects = null;
  experience = null;

  constructor(
    private analyticsService: AnalyticsService,
    private userApiService: UserProfileServiceService,
    private subDomainService: SubdomainService,
  ) {
  }


  subdomain = '';

  ngOnInit(): void {

    this.subdomain = this.subDomainService.getSubdomain();

    if (this.subdomain) {
      this.analyticsService.sendAnalyticPageView("/inicio", "Se entro a inicio")
      this.userApiService.fetchHomeDetails(this.subdomain).subscribe((details) => {
        this.homeDetails = details;
      });

      this.email = localStorage.getItem('email');

      this.userApiService.fetchWork(this.subdomain).subscribe((details) => {
        this.projects = details;
      });

      this.userApiService.fetchExperience(this.subdomain).subscribe((details) => {
        this.experience = details;
      });
    }

  }


}
