<div *ngIf="template_number == 1">
    <app-header-1 [views]="views" [documents]="documents"></app-header-1>
    <router-outlet></router-outlet>
    <app-footer-1 [socialMediaDetails]="socialMediaDetails"></app-footer-1>

</div>

<div *ngIf="template_number == 2">
    <!-- <router-outlet></router-outlet> -->
    <app-header-2 [name]="homeDetails?.name" [views]="views"></app-header-2>

    <app-portfolio [name]="homeDetails?.name" [profession]="homeDetails?.tagline"
        [description]="homeDetails?.description" [email]="email" [homeDetails]="homeDetails"></app-portfolio>
    <app-services [projects]="projects" [experiences]="experiences"></app-services>
    <!-- <app-projects></app-projects> -->

    <app-footer-2 [socialMediaDetails]="socialMediaDetails"></app-footer-2>
</div>

<div *ngIf="template_number==0">
    <div class="spinner"></div>
</div>

<div *ngIf="template_number==3">
    <app-portfolio-template3 [views]="views" [email]="email" [homeDetails]="homeDetails" [projects]="projects"
        [experiences]="experiences" [documents]="documents"
        [socialMediaDetails]="socialMediaDetails"></app-portfolio-template3>
</div>

<div *ngIf="template_number==4">
    <app-template4 [email]="email" [views]="views" [homeDetails]="homeDetails" [projects]="projects"
        [experiences]="experiences" [documents]="documents" [socialMediaDetails]="socialMediaDetails"></app-template4>
</div>


<div *ngIf="template_number==5">
    <app-template5-service [email]="email" [views]="views" [homeDetails]="homeDetails" [projects]="projects"
        [socialMediaDetails]="socialMediaDetails" [documents]="documents"></app-template5-service>
</div>

<div *ngIf="template_number==6">
    <app-template6 [email]="email" [views]="views" [documents]="documents" [homeDetails]="homeDetails"
        [socialMediaDetails]="socialMediaDetails"></app-template6>
</div>

<div *ngIf="template_number==7">
    <app-template7-single-page [email]="email" [views]="views" [documents]="documents" [projects]="projects"
        [homeDetails]="homeDetails" [socialMediaDetails]="socialMediaDetails"></app-template7-single-page>
</div>

<div *ngIf="subdomain=='play'">
    <app-play></app-play>
</div>