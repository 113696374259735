<!-- ======= Footer ======= -->
<footer id="template_2_footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="socials text-center">
                    <ul>
                        <li *ngFor="let socialMediaDetail of socialMediaDetails">
                            <a (click)="analyticsService.sendAnalyticEvent('click_' + socialMediaDetail.name.toLowerCase(), 'footer', socialMediaDetail.name.toLowerCase())"
                                href="{{socialMediaDetail.link}}" target="_blank">
                                <span class="ico-circle">
                                    <i class="fab fa-{{socialMediaDetail.name.toLowerCase()}}"></i>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="copyright-box">
                    <p class="copyright">&copy; {{ year }} Copyright <strong>Personal Brands</strong>. All Rights
                        Reserved</p>
                    <div class="credits">
                        Designed by <a href="#">Personal Brands</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer><!-- End  Footer -->