import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.css']
})
export class PlayComponent implements OnInit {


  public strings = [
    "Kiss each other on cheeks",
    "Hug",
    "normal Kiss on lips",
    "passionate kiss on lips",
    "neck kiss on girl",
    "boobs kiss both",
    "cock suck 4 times",
    "cock suck 2 mins",
    "deep throat",
    "pussy lick hard",
    "fingering hard",
    "hand job hard",
    "hard ass kiss to girl",
    "hard ass kiss to boy",
    "Kiss each other on cheeks",
    "Hug",
    "normal Kiss on lips",
    "passionate kiss on lips",
    "passionate kiss on lips",
    "passionate kiss on lips",
    "passionate kiss on lips",
    "passionate kiss on lips",
    "passionate kiss on lips",
    "neck kiss on girl",
    "boobs kiss both",
    "boobs kiss both",
    "boobs kiss both",
    "boobs kiss both",
    "boobs kiss both",
    "cock suck 4 times",
    "cock suck 2 mins",
    "cock suck 4 times",
    "cock suck 2 mins",
    "cock suck 4 times",
    "cock suck 2 mins",
    "deep throat",
    "pussy lick hard",
    "deep throat",
    "pussy lick hard",
    "deep throat",
    "pussy lick hard",
    "fingering hard",
    "hand job hard",
    "hand job hard",
    "hand job hard",
    "hand job hard",
    "hand job hard",
    "hard ass kiss to girl",
    "hard ass kiss to boy",
    "69",
    "fuck each other",
    "pussy eating 2 mins"
  ];

  public selectedString?: string;

  constructor() { }

  ngOnInit(): void {
  }

  public isAnimating = false;

  getRandomString(): void {
    this.selectedString = '';
    this.isAnimating = true; // Start animation
    setTimeout(() => {
      const randomIndex = Math.floor(Math.random() * this.strings.length);
      this.selectedString = this.strings[randomIndex];
      this.isAnimating = false; // End animation
    }, 2000); // Delay increased to 2 seconds
  }

}
