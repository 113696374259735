<section class="section jobs" id='jobs'>
  <div class="container">
    <div class="section-box">
      <div class="about-title mb-5" data-aos="fade-up" data-aos-duration="1000">
        <h3 class="e-font section-title">
          <span class='code-font n-section-title'>02.</span> {{"Experience.Title" | translate}}
        </h3>
      </div>
      <div class="jobs-tabs" data-aos="fade-up" data-aos-duration="1000">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" orientation="vertical" class="nav-tabs jobs-tabs">
          <li *ngFor='let job of experience; let i = index' [title]="job.projectName" [ngbNavItem]="i">
            <a ngbNavLink [title]="job.projectName">{{ job.projectName.length > 10 ? (job.projectName | slice:0:10) +
              '...' : job.projectName }}</a>
            <ng-template ngbNavContent>
              <h4 class='title-tab-content' [title]="job.projectName">{{ job.projectName | slice:0:8 }}</h4> |
              <span class="company-tab-content" [title]="job.role">{{ job.role | slice:0:10 }}</span>
              <h5 class="job-time" [title]="job.location">{{ job.location | slice:0:10 }} | {{ job.startTime |
                slice:0:10 }} to {{
                job.endTime | slice:0:10 }}</h5>
              <p class="job-description" *ngIf="job.projectPoint1"
                [innerHTML]="(job.projectPoint1 | slice:0:50) + (job.projectPoint1.length > 50 ? '...' : '')"
                [title]="job.projectPoint1"></p>
              <p class="job-description" *ngIf="job.projectPoint2"
                [innerHTML]="(job.projectPoint2 | slice:0:50) + (job.projectPoint2.length > 50 ? '...' : '')"
                [title]="job.projectPoint2"></p>
              <p class="job-description" *ngIf="job.projectPoint3"
                [innerHTML]="(job.projectPoint3 | slice:0:50) + (job.projectPoint3.length > 50 ? '...' : '')"
                [title]="job.projectPoint3"></p>


              <span></span>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>

    </div>
  </div>
</section>