<!-- ======= Services Section ======= -->
<!-- <div class="timeline">
    <div class="timeline-item" data-year="2022" *ngFor="let experience of experiences">
        <div class="timeline-marker"></div>
        <div class="timeline-content">
            <h3>{{experience.projectName}}</h3>
            <p>{{experience.role}}</p>
        </div>
    </div>
</div> -->

<div class="timeline">
    <div class="timeline-item" *ngFor="let experience of experiences" data-aos="fade-up" data-aos-duration="2000">
        <div class="timeline-marker"></div>
        <div class="timeline-content">
            <h3 style="font-weight: bold;">{{experience.projectName}}</h3>
            <p style="color: grey;">{{experience.role}}</p>
            <p><span style="font-weight: bold;">Location:</span> {{experience.location}}</p>
            <p><span style="font-weight: bold;">Duration:</span> {{experience.startTime}} - {{experience.endTime}}</p>
            <div *ngIf="experience.projectPoint1 || experience.projectPoint2 || experience.projectPoint3">
                <p><span style="font-weight: bold;">Key Points:</span></p>
                <ul>
                    <li *ngIf="experience.projectPoint1">{{experience.projectPoint1}}</li>
                    <li *ngIf="experience.projectPoint2">{{experience.projectPoint2}}</li>
                    <li *ngIf="experience.projectPoint3">{{experience.projectPoint3}}</li>
                </ul>
            </div>
            <p *ngIf="experience.website"><span style="font-weight: bold;">Website:</span> <a
                    href="{{experience.website}}">{{experience.website}}</a></p>
        </div>
    </div>
</div>




<section id="template_2_services" class="services-mf pt-5 route">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="title-box text-center pt-5">
                    <h3 class="title-a text-light">
                        Projects
                    </h3>
                    <p class="subtitle-a text-light">
                        These are some Projects
                    </p>
                    <div class="line-mf"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4" *ngFor="let project of projects">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-link"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title" [attr.title]="project.name">
                            {{ project.name.length > 20 ? (project.name | slice:0:20) + '...' : project.name }}
                        </h2>

                        <!-- Project Description -->
                        <p class="s-description text-center" style="height: 100px;" [attr.title]="project.description">
                            {{ project.description.length > 100 ? (project.description | slice:0:100) + '...' :
                            project.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><!-- End Services Section -->