import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './components/home/home.module';
import { GeneralModule } from './components/general/general.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FooterComponent1 } from './components/general/footer/footer.component';
import { HeaderComponent1 } from './components/general/header/header.component';
import { PortfolioComponent } from './template_2/pages/portfolio/portfolio.component';
import { ProjectsComponent } from './template_2/pages/projects/projects.component';
import { ServicesComponent } from './template_2/pages/services/services.component';
import { HeaderComponent2 } from './template_2/shared/header/header.component';
import { FooterComponent2 } from './template_2/shared/footer/footer.component';
import { PortfolioTemplate3Component } from './portfolio-template3/portfolio-template3.component';
import { AboutTemplate3Component } from './about-template3/about-template3.component';
import { HomeTemplate3Component } from './home-template3/home-template3.component';
import { Template4Component } from './template4/template4.component';
import { Template5ServiceComponent } from './template5-service/template5-service.component';
import { Template6Component } from './template6/template6.component';
import { Template7SinglePageComponent } from './template7-single-page/template7-single-page.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PlayComponent } from './play/play.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent2,
    FooterComponent2,
    PortfolioComponent,
    ProjectsComponent,
    ServicesComponent,
    PortfolioTemplate3Component,
    AboutTemplate3Component,
    HomeTemplate3Component,
    Template4Component,
    Template5ServiceComponent,
    Template6Component,
    Template7SinglePageComponent,
    PlayComponent
  ],
  imports: [
    BrowserAnimationsModule,

    HomeModule,
    GeneralModule,

    CommonModule,
    FormsModule,
    AnimateOnScrollModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxGoogleAnalyticsModule.forRoot(environment.trackAnalyticID),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [TranslateService],
  bootstrap: [AppComponent]
})
export class AppModule { }
