<header class="px-2 py-4 mt-16 flex flex-col justify-center items-center text-center">
    <img class="inline-flex object-cover border-4 border-white-600 rounded-full shadow-[5px_5px_0_0_rgba(0,0,0,1)] shadow-white-600/100 bg-white-50 text-white-600 "
        [src]="homeDetails?.profile" alt="" style="    border-radius: 30vw;
        width: 10vw;
        height: 10vw;">
    <h1 class="text-2xl text-white-900 font-bold mt-2 color-white">
        {{homeDetails?.name}}
    </h1>
    <h2 class="text-base md:text-xl text-white-900 font-bold color-white">


        <a href="" target="_blank"
            class="text-white-900 hover:text-white-600 font-bold border-b-0 hover:border-b-4 hover:border-b-white-300 transition-all mb-2 color-white">
            {{homeDetails?.tagline}}
        </a>
    </h2>

    <h3 class="text-base md:text-xl text-white-400 mt-4 mb-4 description color-white" style="font-size: 12px;">

        <a href="" target="_blank"
            class="text-white-400 color-white hover:text-white-400 border-b-0 hover:border-b-4 hover:border-b-white-300 transition-all mb-2">
            {{homeDetails?.description}}
        </a>
    </h3>

</header>

<div *ngFor="let media of socialMediaDetails"
    class="links bg-whites  flex p-4 m-10 gap-3 items-center justify-center rounded-2xl border border-blue-600 ">

    <i class=" h-6 w-6 fill-current text-blue-600 ml-4 fas fa-link"></i>
    <div class="ml-10 font-sans text-xs leading-6 text-blue-600" (click)="navigateLink(media.link)">
        {{ media.name }}
    </div>
    <button type="button" class="ml-auto inline-flex h-5 w-5 rounded-full  p-0.5 text-blue-600" aria-label="Close">
        <i class=" fas fa-chart-bar" (click)="viewAnalysis()"></i>
    </button>
</div>