<div class="overflow-hidden bg-gray-900">
    <div class="px-4 py-20 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="flex flex-col items-center justify-between xl:flex-row">
            <div class="w-full max-w-xl mb-12 xl:pr-16 xl:mb-0 xl:w-7/12">
                <h2
                    class="max-w-lg mb-10 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                    Hi I am

                    <a (click)="toggleViewCount()" class="text-white cursor-pointer views">
                        <i *ngIf="!showViews" class="fas fa-eye"></i>
                        <span *ngIf="showViews">
                            <i class="fas fa-eye-slash"></i>: {{ views }}
                        </span>
                    </a>
                    <br class="hidden md:block" />



                </h2>

                <h1 class="max-w-lg mb-10 font-sans text-7xl font-bold tracking-tight text-white sm:text-7xl sm:leading-none"
                    data-aos="fade-up" data-aos-duration="1000">
                    {{homeDetails?.name}}
                    <br class="hidden md:block" />



                </h1>

                <h2
                    class="max-w-lg mb-10 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">

                    <br class="hidden md:block" />
                    <span id="typed-profession">
                        {{homeDetails?.tagline}}
                    </span>



                </h2>

                <p class="max-w-xl mb-4 text-base text-gray-400 md:text-lg">
                    <span class="text-teal-accent-400">
                        {{ homeDetails?.description | slice:0:200 }}...
                    </span>

                </p>
            </div>
            <div class="w-full max-w-xl xl:px-8 xl:w-5/12">
                <div class="relative">
                    <svg viewBox="0 0 52 24" fill="currentColor"
                        class="absolute bottom-0 right-0 z-0 hidden w-32 -mb-8 -mr-20 text-teal-accent-400 lg:w-32 lg:-mr-16 sm:block">
                        <defs>
                            <pattern id="766323e1-e594-4ffd-a688-e7275079d540" x="0" y="0" width=".135" height=".30">
                                <circle cx="1" cy="1" r=".7"></circle>
                            </pattern>
                        </defs>
                        <rect fill="url(#766323e1-e594-4ffd-a688-e7275079d540)" width="52" height="24"></rect>
                    </svg>
                    <!-- <h3 class="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
                        Sign up for updates
                    </h3>
                    <form>
                        <div class="mb-1 sm:mb-2">
                            <label for="name" class="inline-block mb-1 font-medium">Name</label>
                            <input placeholder="John Doe" required="" type="text"
                                class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                id="name" name="name" />
                        </div>
                        <div class="mb-1 sm:mb-2">
                            <label for="email" class="inline-block mb-1 font-medium">E-mail</label>
                            <input placeholder="john.doe@example.org" required="" type="text"
                                class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                id="email" name="email" />
                        </div>
                        <div class="mt-4 mb-2 sm:mb-4">
                            <button type="submit"
                                class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                                Subscribe
                            </button>
                        </div>
                        <p class="text-xs text-gray-600 sm:text-sm">
                            We respect your privacy. Unsubscribe at any time.
                        </p>
                    </form> -->
                </div>
            </div>
        </div>
    </div>
</div>







<!-- about me  -->




<div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <p class="mb-2 text-xs font-semibold tracking-wide text-gray-600 uppercase sm:text-center">
        <!-- 20 Nov 2020 -->
    </p>
    <div class="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl">
        <div class="mb-4">
            <a aria-label="Article"
                class="inline-block max-w-lg font-sans text-3xl font-extrabold leading-none tracking-tight text-black transition-colors duration-200 hover:text-deep-purple-accent-700 sm:text-4xl">
                About Me
            </a>
        </div>
        <p *ngIf="!readMore" class="text-base text-gray-700 md:text-lg description-tooltip"
            [attr.title]="homeDetails?.description" data-aos="fade-up" data-aos-duration="1000">
            {{ homeDetails?.description | slice:0:calculateSlicePoint(homeDetails?.description) }}...
        </p>

        <p *ngIf="readMore" class="text-base text-gray-700 md:text-lg description-tooltip"
            [attr.title]="homeDetails?.description">
            {{ homeDetails?.description }}
        </p>
    </div>

    <div class="sm:text-center">
        <a style="cursor: pointer;" aria-label="" *ngIf="!readMore" (click)="readMoreToggle(true)"
            class="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">Read
            more</a>

        <a style="cursor: pointer;" aria-label="" *ngIf="readMore" (click)="readMoreToggle(false)"
            class="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            Less</a>
    </div>
</div>















<div class="bg-gray-200 px-2 py-1">

    <div id="features" class="mx-auto max-w-6xl features">
        <p class="text-center text-base font-semibold leading-7 text-primary-500 m-3">Features</p>
        <h2 class="text-center font-display text-3xl font-bold tracking-tight text-slate-900 md:text-4xl
        hover:text-deep-purple-accent-700
        mb-5
        ">
            My Projects and links
        </h2>
        <ul class="mt-16 grid grid-cols-1 gap-6 text-center text-slate-700 md:grid-cols-3">


            <li data-aos="fade-up" data-aos-duration="1000" *ngFor="let project of projects"
                class="rounded-xl bg-white px-6 py-8 shadow-sm">

                <img src="https://www.svgrepo.com/show/530438/ddos-protection.svg" alt="" class="mx-auto h-10 w-10">
                <h3 class="my-3 font-display font-medium" (click)="openLink(project)" title="{{project.name}}">
                    {{project.name | slice:0:20}}{{project.name.length > 20 ? '...' : ''}}
                </h3>
                <p class="mt-1.5 text-sm leading-6 text-secondary-500" (click)="openLink(project)"
                    title="{{project.description}}">
                    {{project.description | slice:0:200}}{{project.description.length > 200 ? '...' : ''}}
                </p>


            </li>
        </ul>
    </div>

    <div>












        <div _ngcontent-udb-c120="" style="margin-top: 50px;"
            class="px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-1">
            <div _ngcontent-udb-c120="" class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-1">
                <div _ngcontent-udb-c120="" style="text-align: center;">
                    <p _ngcontent-udb-c120="" class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400
                        ">
                        My Journey </p>
                </div>
                <h2 _ngcontent-udb-c120="" style="text-align: center;"
                    class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                    <span _ngcontent-udb-c120="" class="relative inline-block"><svg _ngcontent-udb-c120=""
                            viewBox="0 0 52 24" fill="currentColor"
                            class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                            <defs _ngcontent-udb-c120="">
                                <pattern _ngcontent-udb-c120="" id="d0d83814-78b6-480f-9a5f-7f637616b267" x="0" y="0"
                                    width=".135" height=".30">
                                    <circle _ngcontent-udb-c120="" cx="1" cy="1" r=".7"></circle>
                                </pattern>
                            </defs>
                            <rect _ngcontent-udb-c120="" fill="url(#d0d83814-78b6-480f-9a5f-7f637616b267)" width="52"
                                height="24"></rect>
                        </svg><span _ngcontent-udb-c120="" class="relative hover:text-deep-purple-accent-700">My
                            Journey</span></span>
                </h2>
            </div>
        </div>























        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div class="grid gap-6 row-gap-10 lg:grid-cols-1">
                <div class="lg:py-6 lg:pr-16" *ngIf="experiences && experiences.length>0">
                    <div data-aos="fade-up" data-aos-duration="1000" *ngFor="let experience of experiences"
                        class="flex">
                        <div class="flex flex-col items-center mr-4">
                            <div>
                                <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                                    <svg class="w-4 text-gray-600" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                                        <line fill="none" stroke-miterlimit="10" x1="12" y1="2" x2="12" y2="22"></line>
                                        <polyline fill="none" stroke-miterlimit="10" points="19,15 12,22 5,15">
                                        </polyline>
                                    </svg>
                                </div>
                            </div>
                            <div class="w-px h-full bg-gray-300"></div>
                        </div>
                        <div class="pt-1 pb-8">
                            <p class="mb-2 text-lg font-bold">{{experience?.projectName}} |
                                {{experience?.role}} | {{experience?.location}} |

                                {{experience?.startTime}} - {{experience?.endTime}}
                            </p>
                            <li *ngIf="experience?.projectPoint1" class="text-gray-700">
                                {{experience?.projectPoint1}}


                            </li>
                            <li *ngIf="experience?.projectPoint2" class="text-gray-700">
                                {{experience?.projectPoint2}}
                            </li>
                            <li *ngIf="experience?.projectPoint3" class="text-gray-700">
                                {{experience?.projectPoint3}}


                            </li>
                        </div>
                    </div>

                </div>
                <div class="relative">

                </div>
            </div>
        </div>




        <div class="downloalButton mt-6 mb-10" (click)="download()">
            <button class="botao">
                <svg width="36px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="mysvg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0">
                    </g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <g id="Interface / Download">
                            <path id="Vector" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12" stroke="#f1f1f1"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            </path>
                        </g>
                    </g>
                </svg>
                <span class="texto">View My Resume</span>
            </button>

        </div>











        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div style="text-align: center;">
                <p
                    class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                    Social Media
                </p>
            </div>
            <h2 style="text-align: center;" class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto
                hover:text-deep-purple-accent-700
                ">
                <span class="relative inline-block">
                    <svg viewBox="0 0 52 24" fill="currentColor"
                        class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                        <defs>
                            <pattern id="d0d83814-78b6-480f-9a5f-7f637616b267" x="0" y="0" width=".135" height=".30">
                                <circle cx="1" cy="1" r=".7"></circle>
                            </pattern>
                        </defs>
                        <rect fill="url(#d0d83814-78b6-480f-9a5f-7f637616b267)" width="52" height="24"></rect>
                    </svg>
                    <span class="relative">Social</span>
                </span>
                Connect with Me
            </h2>
        </div>

        <div class="shareArticle">
            <div class="shareSocial">
                <br class="hidden md:block" />
                <ul class="socialList" data-aos="fade-left" data-aos-duration="500">
                    <li *ngFor="let media of socialMediaDetails">
                        <a href="{{media.link}}" target="_blank"><i class="fab fa-{{media.name | lowercase}}"></i></a>
                    </li>
                </ul>



            </div>

        </div>


        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div class="max-w-2xl mx-auto sm:max-w-xl md:max-w-2xl">
                <div class="text-center">
                    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">


                    </div>
                    <form class="flex flex-col items-center w-full mb-4 md:flex-row md:px-16">
                        <input placeholder="Email" required="" type="text" [value]="email" disabled
                            class="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline" />
                        <button type="submit" style="top: -10px !important; position: relative;" (click)="sendMail()"
                            class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                            Mail
                        </button>

                    </form>
                    <p class="max-w-md mx-auto mb-10 text-xs text-gray-600 sm:text-sm md:mb-16">
                        Email me to connect with me
                    </p>
                </div>
            </div>
        </div>




        <div class="relative mt-16 bg-deep-purple-accent-400">
            <svg class="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-deep-purple-accent-400"
                preserveAspectRatio="none" viewBox="0 0 1440 54">
                <path fill="currentColor"
                    d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z">
                </path>
            </svg>
            <div class="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
                <div class="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
                    <div class="md:max-w-md lg:col-span-2">
                        <a href="/" aria-label="Go home" title="Company" class="inline-flex items-center">
                            <svg class="w-8 text-teal-accent-400" viewBox="0 0 24 24" stroke-linejoin="round"
                                stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" stroke="currentColor"
                                fill="none">
                                <rect x="3" y="1" width="7" height="12"></rect>
                                <rect x="3" y="17" width="7" height="6"></rect>
                                <rect x="14" y="1" width="7" height="6"></rect>
                                <rect x="14" y="11" width="7" height="12"></rect>
                            </svg>
                            <span
                                class="ml-2 text-xl font-bold tracking-wide text-gray-100 uppercase">{{homeDetails?.name}}</span>
                        </a>
                        <div class="mt-4 lg:max-w-sm">
                            <p class="text-sm text-deep-purple-50">
                                {{ homeDetails?.description | slice:0:200 }}...
                            </p>

                        </div>
                    </div>
                    <div style="display: none;" class="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
                        <div>
                            <p class="font-semibold tracking-wide text-teal-accent-400">
                                Category
                            </p>
                            <ul class="mt-2 space-y-2">
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">News</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">World</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Games</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">References</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p class="font-semibold tracking-wide text-teal-accent-400">Cherry</p>
                            <ul class="mt-2 space-y-2">
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Web</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">eCommerce</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Business</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Entertainment</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Portfolio</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p class="font-semibold tracking-wide text-teal-accent-400">Apples</p>
                            <ul class="mt-2 space-y-2">
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Media</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Brochure</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Nonprofit</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Educational</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Projects</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p class="font-semibold tracking-wide text-teal-accent-400">
                                Business
                            </p>
                            <ul class="mt-2 space-y-2">
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Infopreneur</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Personal</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Wiki</a>
                                </li>
                                <li>
                                    <a href="/"
                                        class="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400">Forum</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    class="flex flex-col justify-between pt-5 pb-10 border-t border-deep-purple-accent-200 sm:flex-row">
                    <p class="text-sm text-gray-100">
                        © Copyright 2024 {{homeDetails?.name}}. All rights reserved.
                    </p>
                    <div class="flex items-center mt-4 space-x-4 sm:mt-0">
                        <a *ngFor="let media of socialMediaDetails"
                            class="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400">

                            <a href="{{media.link}}" target="_blank"><i
                                    class="fab fa-{{media.name | lowercase}}"></i></a>


                        </a>

                    </div>
                </div>
            </div>
        </div>