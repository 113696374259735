import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-template6',
  templateUrl: './template6.component.html',
  styleUrls: ['./template6.component.css']
})
export class Template6Component implements OnInit, OnDestroy {

  constructor() { }

  messages: string[] = [
    "This is the first message This is the first message This is the first message This is the first message This is the first message  This is the first messageThis is the first messageThis is the first messageThis is the first messageThis is the first message",
    "This is the second message",
    "This is the third message"
  ];

  @Input()
  email;

  @Input()
  homeDetails;

  @Input()
  projects;

  @Input()
  socialMediaDetails;

  @Input()
  views;

  @Input()
  documents;

  ngOnInit(): void {
    document.body.style['background-image'] = 'linear-gradient(to right, #2E3192, #1BFFFF)';
  }

  navigateLink(link) {
    window.open(link, '_blank');
  }

  viewAnalysis() {
    console.log('view');

  }

  ngOnDestroy(): void {
    document.body.style.background = '';
  }

}
