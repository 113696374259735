import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileServiceService {

  constructor(private http: HttpClient) { }

  // apiUrl = "http://localhost:8080";
  apiUrl = 'https://personal-brand-service.onrender.com/';

  fetchHomeDetails(webSite): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/home-details/${webSite}`);
  }

  fetchEmail(webSite): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/website/${webSite}`);
  }

  fetchSocialMedia(webSite): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/fetch-social-media/${webSite}`);
  }

  fetchWork(webSite): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/fetch-work/${webSite}`);
  }

  fetchExperience(webSite): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/fetch-experience/${webSite}`);
  }

  incrementViewCount(viewName) {
    return this.http.post(`${this.apiUrl}/api/increment-view-count/${viewName}`, {});
  }

  getViewCount(viewName) {
    return this.http.get(`${this.apiUrl}/api/get-view-count/${viewName}`);
  }

  fetchPersonalInfo(website): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/api/fetch-personal-details/${website}`
    );
  }

  fetchDocDetails(website): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/api/fetch-document-details/${website}`
    );
  }


  fetchContactInfo(website): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/api/fetch-contact-details/${website}`
    );
  }

  fetchServices(website): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/api/fetch-service-details/${website}`
    );
  }


  fetchFamilyDetails(website): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/api/fetch-family-details/${website}`
    );
  }


}
