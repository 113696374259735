<div class=" w-full max-w-7xl">
    <div
        class="flex flex-col max-w-screen-xl p-5 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
        <div class="flex flex-row items-center justify-between lg:justify-start">
            <a style="font-size: 2rem;"
                class="text-lg font-bold tracking-tighter text-blue-600 transition duration-500 ease-in-out transform tracking-relaxed lg:pr-8">
                {{homeDetails?.name}} </a>


            <i (click)="toggleOpen()" style="font-size: 26px;" class="
                rounded-lg md:hidden focus:outline-none focus:shadow-outline cursor-pointer
                hover:text-blue-600 fa fa-bars"></i>

        </div>

        <nav [ngClass]="{'flex': open, 'hidden': !open}"
            class="flex-col items-center flex-grow hidden pb-4 border-blue-600 md:pb-0 md:flex md:justify-end md:flex-row lg:border-l-2 lg:pl-2">
            <a class=" navigation-link  px-4 py-2 mt-2 text-md text-gray-500 md:mt-0 hover:text-blue-600 focus:outline-none focus:shadow-outline cursor-pointer"
                id="template5About">About</a>
            <a id="template5Services"
                class=" navigation-link  px-4 py-2 mt-2 text-md text-gray-500 md:mt-0 hover:text-blue-600 focus:outline-none focus:shadow-outline cursor-pointer">Services</a>
            <!-- <a id="template5Products"
                class=" navigation-link  px-4 py-2 mt-2 text-sm text-gray-500 md:mt-0 hover:text-blue-600 focus:outline-none focus:shadow-outline cursor-pointer">Products</a> -->
            <a id="template5Work"
                class=" navigation-link  px-4 py-2 mt-2 text-md text-gray-500 md:mt-0 hover:text-blue-600 focus:outline-none focus:shadow-outline cursor-pointer">My
                Work</a>
            <a id="template5Contact"
                class=" navigation-link px-4 py-2 mt-2 text-md text-gray-500 md:mt-0 hover:text-blue-600 focus:outline-none focus:shadow-outline cursor-pointer">Contact</a>





            <!-- <div class="inline-flex items-center gap-2 list-none lg:ml-auto">
                <button
                    class="items-center block px-10 py-2.5 text-base font-medium text-center text-blue-600 transition duration-500 ease-in-out transform border-2 border-white shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">Sign
                    in</button>
                <button
                    class="items-center block px-10 py-3 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Sign
                    up</button>
            </div> -->
        </nav>
    </div>
</div>



<section class="w-full bg-white min-h-screen" style="margin-top: 5rem;">
    <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-16 max-w-7xl lg:py-24">
        <div class="flex w-full mx-auto text-left">
            <div class="relative inline-flex items-center mx-auto align-middle">
                <div class="text-center">
                    <h1
                        class="max-w-6xl mx-auto text-2xl font-bold leading-snug tracking-wide text-neutral-600 md:text-5xl md:leading-normal lg:text-6xl lg:leading-normal lg:max-w-7xl lg:tracking-wider">
                        {{homeDetails?.tagline}}
                    </h1>




                    <!-- <p class="max-w-xl mx-auto mt-8 text-base leading-relaxed text-gray-500">
                        Discover our comprehensive suite of services and products tailored to boost your business's
                        growth. From innovative digital enhancements to bespoke solutions, find everything you need to
                        succeed—backed by competitive pricing and special deals.
                    </p> -->


                    <div class="flex justify-center w-full max-w-2xl gap-2 mx-auto" style="margin-top: 3rem;">
                        <div class="mt-3 rounded-lg sm:mt-0">
                            <button style="padding: 1rem 3rem !important;" (click)="sendMail()"
                                class="items-center block px-5 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 lg:px-10 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                Email me
                            </button>

                        </div>
                        <div class="mt-3 rounded-lg sm:mt-0 sm:ml-3">
                            <button id="seeFeatures"
                                class="items-center block px-5 lg:px-10 py-3.5 text-base font-medium text-center text-blue-600 transition duration-500 ease-in-out transform border-2 border-white shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">See
                                Services</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div id="template5AboutDetails" class=" mt-10">

    <section>
        <div class="2xl:max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24 2xl:px-12 px-4 py-1 mx-auto">
            <div class="2xl:max-w-7xl">
                <div class="lg:flex-grow lg:pr-24 md:mb-0  flex-col items-start mb-1 text-left">
                    <h1 class="mb-1 text-lg font-bold tracking-widest text-blue-600 uppercase"
                        style="text-align: center !important;">
                        About Me </h1>

                </div>

            </div>
        </div>
    </section>

    <section>
        <div class="px-4 py-2 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24">
            <div class="flex flex-col w-full mb-12 text-center">

                <p class=" mt-4 text-base leading-relaxed text-center text-gray-5p-4 mb-8 text-lg tracking-widest
                text-gray-500
                ">

                    {{homeDetails?.description}}

                </p>
            </div>
        </div>
    </section>

</div>



<div _ngcontent-vxn-c122="" id="template5ServicesDetails">
    <section _ngcontent-vxn-c122="">
        <div _ngcontent-vxn-c122="" class="2xl:max-w-7xl sm:px-6 md:px-12 lg:px-24  2xl:px-12 px-4 mx-auto">
            <div _ngcontent-vxn-c122="" class="2xl:max-w-7xl">
                <div _ngcontent-vxn-c122="" class="lg:flex-grow lg:pr-24 md:mb-0 flex-col items-start mb text-left">
                    <h1 _ngcontent-vxn-c122=""
                        class="mt-8 mb-2 text-lg font-bold tracking-widest text-blue-600 uppercase"
                        style="text-align: center !important;"> Services By Me </h1>
                </div>
            </div>
        </div>
    </section>

    <!-- <div class="p-6" *ngFor="let service of services; let i = index">
        <img class="object-cover object-center w-full mb-8 lg:h-48 md:h-36 rounded-xl"
            [src]="service.serviceImage ? service.serviceImage : servicesImage" alt="blog">
        <div class="mb-10">
            <div class="inline-flex justify-between w-full">
                <h1 class="text-xl font-semibold leading-none tracking-tighter text-neutral-600">
                    {{service.headline}}</h1>
                <span>{{service.price}}</span>
            </div>
            <p class="text-base font-medium text-left leading-relaxed text-gray-500">
                {{descriptionToShow(service)}}
            </p>
            <a *ngIf="service.description.length > 200" (click)="toggleView(i)" class="text-blue-500 cursor-pointer">
                {{service.viewFull ? 'View Less' : 'View Full'}}
            </a>
        </div>
    </div> -->

    <section class="" *ngFor="let service of services; let i = index">
        <div class="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
                <div class="max-w-lg">
                    <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl"> {{service.name}}</h2>
                    <p class="mt-4 text-gray-600 text-lg"> {{descriptionToShow(service)}}</p>
                    <div class="mt-8">
                        <a (click)="sendMail()"
                            class="text-blue-500 hover:text-blue-600 font-medium cursor-pointer">{{service.price}}
                            /
                            {{service.availability}}
                            <span class="ml-2">&#8594;</span></a>
                    </div>
                </div>
                <div class="mt-12 md:mt-0">
                    <img [src]="service.serviceImage ? service.serviceImage : servicesImage" alt="About Us Image"
                        class="object-cover object-center w-full h-40 md:h-96 rounded-lg shadow-md cursor-pointer"
                        (click)="openImage(service.serviceImage ? service.serviceImage : servicesImage)">
                </div>


            </div>
        </div>
    </section>

</div>













<div id="template5WorkDetails" *ngIf="works && works.length">

    <section>
        <div class="2xl:max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24 2xl:px-12 px-4 py-12 mx-auto">
            <div class="2xl:max-w-7xl">
                <div class="lg:flex-grow lg:pr-24 md:mb-0  flex-col items-start mb-16 text-left">
                    <h1 class="mb-8 text-lg font-bold tracking-widest text-blue-600 uppercase"
                        style="text-align: center !important;">
                        My Work </h1>

                </div>

            </div>
        </div>
    </section>

    <section>
        <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="grid w-full grid-cols-1 gap-6 mx-auto lg:grid-cols-3">
                <div class="p-6" *ngFor="let work of works; let i = index">
                    <img class="object-cover object-center w-full mb-8 lg:h-48 md:h-36 rounded-xl" [src]="productsImage"
                        alt="blog">
                    <div class="mb-10">
                        <div class="inline-flex justify-between w-full">
                            <h1 class="text-xl font-semibold leading-none tracking-tighter text-neutral-600">
                                {{work.name}}</h1>
                        </div>
                        <p class="text-base font-medium text-left leading-relaxed text-gray-500">
                            {{descriptionToShow(work)}}
                        </p>
                        <a *ngIf="work.description.length > 200" (click)="toggleWorkView(i)"
                            class="text-blue-500 cursor-pointer">
                            {{work.viewFull ? 'View Less' : 'View Full'}}
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </section>



</div>


<div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="grid grid-cols-2 row-gap-6 md:grid-cols-4">
        <div class="text-center md:border-r">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">{{views}}</h6>
            <p class="text-sm font-medium tracking-widest  uppercase lg:text-base
            text-blue-500
            ">
                Total Views
            </p>
        </div>
        <div class="text-center md:border-r">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">100</h6>
            <p class="text-sm font-medium tracking-widest  uppercase lg:text-base
            text-blue-500
            ">
                Total Customers
            </p>
        </div>
        <div class="text-center md:border-r">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">48</h6>
            <p class="text-sm font-medium tracking-widest  uppercase lg:text-base
            text-blue-500
            ">
                Total Sales
            </p>
        </div>
        <div class="text-center md:border-r">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">48</h6>
            <p class="text-sm font-medium tracking-widest  uppercase lg:text-base
            text-blue-500
            ">
                Total Social Interactions
            </p>
        </div>
    </div>
</div>





<!-- This example requires Tailwind CSS v2.0+
    -->
<footer id="template5ContactDetails" class="bg-white" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>

    <div class="px-5 py-12 mx-auto bg-gray-50 max-w-7xl sm:px-6 md:flex md:items-center md:justify-between lg:px-20">
        <div class="flex justify-center mb-8 space-x-6 md:order-last md:mb-0">

            <div *ngFor="let socialMediaDetail of socialMediaDetails">
                <a [href]="socialMediaDetail.link" target="_blank">
                    <i style="font-size: 26px;"
                        class="text-gray-500 hover:text-blue-600 fab fa-{{socialMediaDetail.name.toLowerCase()}}"></i>
                </a>
            </div>


        </div>
        <div class="mt-8 md:mt-0 md:order-1">
            <span class="mt-2 text-sm font-light text-gray-500">
                Copyright © 2024 - 2025
                <a href="#" class="mx-2 text-wickedblue hover:text-gray-500"
                    rel="noopener noreferrer">@{{homeDetails?.name}}</a>. Since 2024
            </span>
        </div>
    </div>
</footer>