import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Typed from 'typed.js';

@Component({
  selector: 'app-portfolio-template3',
  templateUrl: './portfolio-template3.component.html',
  styleUrls: ['./portfolio-template3.component.css']
})
export class PortfolioTemplate3Component implements OnInit, OnChanges {

  @Input()
  email;

  @Input()
  homeDetails;

  @Input()
  projects;

  @Input()
  experiences;

  @Input()
  socialMediaDetails;

  @Input()
  documents;


  @Input()
  views;


  showViews = false;

  menu = false;

  menuToggle() {
    this.menu = !this.menu;
  }

  constructor() { }

  typed = false;
  ngOnInit(): void {


  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.homeDetails.tagline) {
      if (this.typed == false) {
        const typedProfession = new Typed('#typed-profession', {
          strings: [this.homeDetails.tagline],
          typeSpeed: 90,
          loop: true
        });
        this.typed = true;
      }


    }

  }

  readMore = false;

  readMoreToggle(readMore) {
    this.readMore = readMore;
  }

  calculateSlicePoint(description: string | null): number {
    return description ? Math.floor(description.length * 0.6) : 0;
  }


  sendMail() {
    window.location.href = `mailto:${this.email}`;

  }

  openLink(project) {
    window.open(project.codeLink, '_blank');
    window.open(project.link, '_blank');
  }

  toggleViewCount() {
    this.showViews = !this.showViews;
  }

  download() {
    if (this.documents && this.documents.length > 0) {
      const resumeDocument = this.documents.find(document => document.name.toLowerCase() === 'resume');
      if (resumeDocument) {
        window.open(resumeDocument.path, "_blank");
      }
    }
  }

}
