<!-- ======= Intro Section ======= -->
<div id="template_2_home" class="intro route bg-image" style="background-image: url(assets/img/intro-bg.jpg)">
    <div class="overlay-itro"></div>
    <div class="intro-content display-table">
        <div class="table-cell">
            <div class="container">
                <h1 class="intro-title mb-4">I am {{name}}</h1>
                <!-- <h1 class="intro-title mb-4">{{profession}}</h1> -->
                <p class="intro-subtitle"><span class="text-slider-items" id="typed-profession1">

                        <!-- {{profession}} -->
                    </span><strong class="text-slider"></strong></p>

                <!-- <h1 class="intro-title mb-4">I am <span id="typed-name"></span></h1> -->
                <p class="intro-subtitle">I am <span id="typed-profession"></span></p>
            </div>
        </div>
    </div>
</div><!-- End Intro Section -->

<main id="main">

    <!-- ======= About Section ======= -->
    <section id="template_2_about" class="about-mf sect-pt4 route">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="box-shadow-full">
                        <div class="row">
                            <div class="col-md-6">
                                <div *ngFor="let person of _service.profile" class="row">
                                    <div class="col-sm-6 col-md-5">
                                        <div class="about-img">
                                            <img [src]="'assets/img/person_1.avif'" class="img-fluid rounded b-shadow-a"
                                                alt="">
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-7">
                                        <div class="about-info">
                                            <p><span class="title-s">Name: </span> <span>{{ name }}</span></p>
                                            <p><span class="title-s">Profile: </span> <span>{{ profession
                                                    }}</span>
                                            </p>
                                            <p><span class="title-s">Email: </span> <span>{{ email }}</span></p>
                                            <!-- <p><span class="title-s">Phone: </span> <span>{{ person.phone }}</span></p> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="skill-mf">
                                    <p class="title-s">Skills</p>
                                    <ul class="skills-list">
                                        <li *ngIf="homeDetails.skill1" data-aos="fade-up" data-aos-duration="1000">{{
                                            homeDetails.skill1 }}</li>
                                        <li *ngIf="homeDetails.skill2" data-aos="fade-up" data-aos-duration="1000">{{
                                            homeDetails.skill2 }}</li>
                                        <li *ngIf="homeDetails.skill3" data-aos="fade-up" data-aos-duration="1000">{{
                                            homeDetails.skill3 }}</li>
                                        <li *ngIf="homeDetails.skill4" data-aos="fade-up" data-aos-duration="1000">{{
                                            homeDetails.skill4 }}</li>
                                        <li *ngIf="homeDetails.skill5" data-aos="fade-up" data-aos-duration="1000">{{
                                            homeDetails.skill5 }}</li>
                                        <li *ngIf="homeDetails.skill6" data-aos="fade-up" data-aos-duration="1000">{{
                                            homeDetails.skill6 }}</li>
                                    </ul>

                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="about-me pt-4 pt-md-0">
                                    <div class="title-box-2">
                                        <h5 class="title-left">
                                            About me
                                        </h5>
                                    </div>
                                    <p class="lead" title="{{description}}" data-aos="fade-up" data-aos-duration="1000">
                                        {{ description.length > 700 ? (description | slice:0:700) + '...' : description
                                        }}
                                    </p>


                                    <!-- <p class="lead">
                                        I constantly work on my self-taught learning, with the help of the different
                                        technological tools that currently interact and intervene in the transformation
                                        and growth of the digital world.
                                    </p> -->
                                    <!-- <p class="lead">
                                        I focus on frontend web development and also single page applications (SPA),
                                        their performance, among other topics, I am currently learning different
                                        libraries and frameworks to carry out multiple software projects more quickly
                                        and easily.
                                    </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section><!-- End About Section -->
</main>