import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { InfoPageService } from '../../services/info-page.service';
import Typed from 'typed.js';
declare var jQuery: any;


@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit, OnChanges {

  constructor(public _service: InfoPageService) { }

  changed = false;
  @Input()
  name;

  @Input()
  profession: string;

  @Input()
  description;

  @Input()
  homeDetails;

  @Input()
  email;


  ngOnInit(): void {

    // (function ($) {
    //   /*--/ Star Typed /--*/
    //   if (true) {
    //     var typed_strings = $('.text-slider-items').text();

    //     console.log(typed_strings);
    //     if (typed_strings) {
    //       var typed = new Typed('.text-slider', {
    //         strings: [typed_strings],
    //         typeSpeed: 80,
    //         loop: true,
    //         backDelay: 1100,
    //         backSpeed: 30
    //       });
    //     }

    //   }
    // })(jQuery);

  }

  ngOnChanges(): void {


    if (!this.changed && this.profession) {
      this.changed = true;
      console.log(this.changed);
      const typedProfession = new Typed('#typed-profession', {
        strings: [this.profession],
        typeSpeed: 80,
        loop: true
      });
    }
  }

  ngAfterContentInit(): void {
    this.initializeTypedProfession();
  }

  initializeTypedProfession(): void {
    if (this.profession) {
      console.log(this.profession);
      const typedProfession = new Typed('#typed-profession', {
        strings: [this.profession],
        typeSpeed: 80,
        loop: false
      });
    } else {
      setTimeout(() => {
        if (!this.profession) {
          this.initializeTypedProfession(); // Call the function recursively after 2 seconds
        }

      }, 2000);
    }
  }
}
