<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-10 mb-4 mb-md-0">
                    <div class="about-description">

                        <p [innerHTML]="(homeDetails?.description | slice:0:600) + (homeDetails?.description.length > 600 ? '...' : '')"
                            class="mt-4" title="{{ homeDetails?.description }}" data-aos="fade-up"
                            data-aos-duration="1000"></p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li *ngIf="homeDetails.skill1" class="skill-element" data-aos="fade-up"
                                data-aos-duration="1000">
                                <span class="underline">{{homeDetails.skill1}}</span>
                            </li>
                            <li *ngIf="homeDetails.skill2" class="skill-element" data-aos="fade-up"
                                data-aos-duration="1000">
                                <span class="underline">{{homeDetails.skill2}}</span>
                            </li>
                            <li *ngIf="homeDetails.skill3" class="skill-element" data-aos="fade-up"
                                data-aos-duration="1000">
                                <span class="underline">{{homeDetails.skill3}}</span>
                            </li>
                            <li *ngIf="homeDetails.skill4" class="skill-element" data-aos="fade-up"
                                data-aos-duration="1000">
                                <span class="underline">{{homeDetails.skill4}}</span>
                            </li>
                            <li *ngIf="homeDetails.skill5" class="skill-element" data-aos="fade-up"
                                data-aos-duration="1000">
                                <span class="underline">{{homeDetails.skill5}}</span>
                            </li>
                            <li *ngIf="homeDetails.skill6" class="skill-element" data-aos="fade-up"
                                data-aos-duration="1000">
                                <span class="underline">{{homeDetails.skill6}}</span>
                            </li>
                        </ul>

                    </div>
                </div>
                <!-- <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")'
                            class="rounded" width="300" height="300" src="assets/images/me.png" alt="José Andrés">
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</section>