import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { trigger, query, stagger, animate, style, transition } from '@angular/animations'
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { UserProfileServiceService } from 'src/app/user-profile-service.service';


@Component({
  selector: 'app-footer-1',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    trigger("animateFooter", [
      transition(":enter", [
        query("*", [
          style({ opacity: 0, transform: "translateY(100%)" }),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ])
      ])
    ])
  ]
})
export class FooterComponent1 implements OnInit {


  mailTo;

  @Input()
  socialMediaDetails = [];

  constructor(
    public analyticsService: AnalyticsService,
    public userProfileService: UserProfileServiceService
  ) { }

  ngOnInit(): void {
    this.mailTo = 'mailto:' + localStorage.getItem('email');
  }

  ngOnChanges(): void {
    console.log(this.socialMediaDetails);
    this.mailTo = 'mailto:' + localStorage.getItem('email');
  }

}
