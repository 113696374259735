import { Component, OnInit } from '@angular/core';
import Typed from 'typed.js';

@Component({
  selector: 'app-home-template3',
  templateUrl: './home-template3.component.html',
  styleUrls: ['./home-template3.component.css']
})
export class HomeTemplate3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const typedProfession = new Typed('#typed-profession', {
      strings: [' jumps over a'],
      typeSpeed: 80,
      loop: true
    });

  }



}
