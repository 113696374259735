<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <li *ngFor="let socialMediaDetail of socialMediaDetails">
                <a (click)="analyticsService.sendAnalyticEvent('click_' + socialMediaDetail.name.toLowerCase(), 'footer', socialMediaDetail.name.toLowerCase())"
                    href="{{socialMediaDetail.link}}" target="_blank">
                    <i class="fab fa-{{socialMediaDetail.name.toLowerCase()}}"></i>
                </a>
            </li>


        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")' [href]="mailTo"
                target='_blank'>{{mailTo}}</a>
        </div>
    </div>

    <div class="footer-credits text-center">
        <a href="#" target="_blank" rel="nofollow noopener noreferrer">
            <div>Built with Angular by Personal Brand</div>
        </a>
        <a href="#" target="_blank" rel="nofollow noopener noreferrer">
            <div class="mt-2">Designed by Personal Brand</div>
        </a>

    </div>

</footer>